import React from 'react'
import { DiagonalBox } from '../DiagonalBox/DiagonalBox'

import { Breakpoints, Colors, Type } from '@/styles'
import styled from 'styled-components'
import LocalizedLink from '../LocalizedLink/LocalizedLink'
import HoverArrow from '../HoverArrow/HoverArrow'

export const StyledListContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px 20px;
  flex-wrap: wrap;
`

export const StyledDiagonalBox = styled(DiagonalBox)`
  transform: scaleX(-1);
  margin-bottom: 200px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 0px;
  }
`

export const Title = styled.h2`
  ${Type.subhead20};
  margin-bottom: 32px;
`

export const StyledArrow = styled(HoverArrow)`
  margin-left: 18px;
  width: 31px;
  transform: scale(0.5);

  @media (min-width: ${Breakpoints.desktop}) {
    margin-left: 24px;
    width: auto;
    top: 3px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: ${Colors.navy};
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;

  &:hover {
    ${StyledArrow} {
      .arrow {
        transform: translate3d(30px, 0, 0);
      }
    }
  }
`
