import React from 'react'
import SecondaryCaseStudyItem from '../SecondaryCaseStudyItem/SecondaryCaseStudyItem'
import * as S from './styles'

const AndMoreSection = ({ caseStudies, homepage }) => {
  return (
    <S.StyledDiagonalBox bg="#F2F2F2">
      {homepage?.and_more && <S.Title>{homepage.and_more}</S.Title>}
      <S.StyledListContainer>
        {caseStudies
          ?.filter(item => item.show_into_secondary_section)
          ?.map(item => (
            <SecondaryCaseStudyItem key={item.uid} url={item.uid} {...item} />
          ))}
      </S.StyledListContainer>

      {homepage?.more_about_us_label && (
        <S.StyledLink to={homepage?.more_about_us_slug}>
          {homepage.more_about_us_label}
          <S.StyledArrow arrowProps={{ role: 'none' }} />
        </S.StyledLink>
      )}
    </S.StyledDiagonalBox>
  )
}

export default AndMoreSection
