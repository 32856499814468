import React from 'react'
import { Width, Navigation } from '@/components'

import * as S from './styles'

const HeroComponent = ({ title, isHomePage }) => {
  return (
    <S.HeroComponent>
      <S.NavContainer>
        <S.StyledWidth>
          <Navigation isHomePage={isHomePage} />
        </S.StyledWidth>
      </S.NavContainer>
      <Width>
        <S.Title>{title}</S.Title>
        <S.StyledHeroArrow role="none" />
      </Width>
    </S.HeroComponent>
  )
}

export default HeroComponent
