import React from 'react'
import LocalizedLink from '../LocalizedLink/LocalizedLink'

import * as S from './styles'

const SecondaryCaseStudyItem = props => {
  const { home_image, title, home_description, url } = props

  const to = `/case-studies/${url}`

  return (
    <LocalizedLink to={to}>
      <S.Container>
        {home_image?.localFile?.childImageSharp?.fluid && (
          <S.StyledImage
            fluid={home_image.localFile.childImageSharp.fluid}
            role="none"
            alt=""
          />
        )}
        {title.text && <S.Title>{title.text}</S.Title>}
        {home_description.text && (
          <S.Description>{home_description.text}</S.Description>
        )}
      </S.Container>
    </LocalizedLink>
  )
}

export default SecondaryCaseStudyItem
