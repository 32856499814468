import styled from 'styled-components'

import { HeroArrow } from '../Icons'
import { Breakpoints, Colors, Sizes } from '@/styles'
import { Width } from '@/components'

export const marginDesktop = 40

export const HeroComponent = styled.div`
  margin-bottom: 132px;
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  margin-top: 222px;

  @media (min-width: ${Breakpoints.tablet}) {
    padding-bottom: 0;
    margin-bottom: 132px;
    margin-top: 272px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 334px;
  }
`

export const StyledWidth = styled(Width)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 10px;
`

export const Title = styled.h1`
  max-width: 692px;
  margin-bottom: 24px;
  margin-top: 0;
  padding-top: 10px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 67px;
    padding-top: 30px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 87px;
    padding-top: ${marginDesktop}px;
  }
`

export const NavContainer = styled.div`
  background-color: ${Colors.offWhite};
  min-height: ${Sizes.navHeight};
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 6;
`

export const StyledHeroArrow = styled(HeroArrow)`
  width: 29px;
  height: 87px;
  margin-left: 10px;

  @media (min-width: ${Breakpoints.tablet}) {
    width: 37px;
    height: 162px;
    margin-left: 4px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: auto;
    height: auto;
  }
`
