import styled from 'styled-components'
import Image from 'gatsby-image'

import { Type } from '@/styles'
import fontColorMap from '@/data/fontColorMap'

export const StyledImage = styled(Image)`
  width: 60%;
  height: 100%;

  transition: transform 0.3s ease-in-out;
`

export const Title = styled.h3`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2e3c4a;

  transition: color 0.3s ease-in-out;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 223px;

  &:hover {
    ${StyledImage} {
      transform: scale(1.2);
    }
    ${Title} {
      color: #eb4646;
    }
  }
`

export const Description = styled.p`
  ${Type.body40};
  color: ${fontColorMap.dark};
`
